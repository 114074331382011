<template>
  <v-card-text>
    <v-autocomplete
      v-model="selected"
      label="Buscar cliente (ID, acrônimo ou nome do banco de dados)"
      :items.sync="foundAccounts"
      :search-input.sync="search"
      outlined
      append-icon="search"
    />
    <v-btn :disabled="!search || !selected" outlined color="primary" @click="redirect">Ir para página do cliente</v-btn>
  </v-card-text>
</template>

<script>
import { QUERY_QUICK_ACCESS } from '@/modules/home/graphql'

export default {
  data: () => ({
    search: '',
    found: [],
    foundAccounts: [],
    selected: ''
  }),
  watch: {
    search(value) {
      if (value && value.trim() !== '') this.getAccounts(value.toLowerCase())
    }
  },
  methods: {
    redirect() {
      const accountId = this.selected.split(' / ')[1]
      setTimeout(() => {
        this.$router.push('/accounts/' + accountId + '/edit/geral')
      }, 100)
    },
    async getAccounts(value) {
      setTimeout(async () => {
        const { data } = await this.$apollo.query({
          query: QUERY_QUICK_ACCESS,
          variables: { param: value }
        })
        this.found = data.quickAccess
        this.foundAccounts = data.quickAccess.map(item => item.db_name + ' / ' + item._id)
      }, 1500)
    }
  }
}
</script>
